export const SET_UNINSTALLATION_FILE_DATA = "SET_UNINSTALLATION_FILE_DATA";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_INSTALLATION_FILE_LIST_COMPLETE = "FETCH_INSTALLATION_FILE_LIST_COMPLETE";
export const OPEN_NEW_UPLOAD_POPUP = "OPEN_NEW_UPLOAD_POPUP";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const DATA_SUBMIT_INIT = "DATA_SUBMIT_INIT";
export const DATA_SUBMIT_DISMISS = "DATA_SUBMIT_DISMISS";
export const UPLOAD_INSTALLATION_FILE_COMPLETE = "UPLOAD_INSTALLATION_FILE_COMPLETE";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const OPEN_ERR_FILE_DOWNLOAD_POPUP = "OPEN_ERR_FILE_DOWNLOAD_POPUP";
export const CLOSE_ERR_FILE_DOWNLOAD_POPUP = "CLOSE_ERR_FILE_DOWNLOAD_POPUP";
export const FETCH_INSTALLATION_FILE_ASSET_LIST_COMPLETE = "FETCH_INSTALLATION_FILE_ASSET_LIST_COMPLETE";
export const UNINSTALLATION_ASSET_FETCHING_INIT = "UNINSTALLATION_ASSET_FETCHING_INIT";
export const UNINSTALLATION_ASSET_FETCHING_COMPLETE = "UNINSTALLATION_ASSET_FETCHING_COMPLETE";
export const UNINSTALLATION_ASSET_FETCHING_DISMISS = "UNINSTALLATION_ASSET_FETCHING_DISMISS";
export const SET_REVIEW_UNINSTALLATION_FILE_DATA = "SET_REVIEW_UNINSTALLATION_FILE_DATA";
export const ACCEPT_UNINSTALL_VALIDATION_SUCCESS = "ACCEPT_UNINSTALL_VALIDATION_SUCCESS";
export const HIDE_UNINSTALL_VALIDATION_FILE_REJECT = "HIDE_UNINSTALL_VALIDATION_FILE_REJECT";
export const OPEN_UNINSTALL_REJECT_VALIDATION_POPUP = "OPEN_UNINSTALL_REJECT_VALIDATION_POPUP";
export const UNINSTALL_REJECT_VALIDATION_COMPLETE = "UNINSTALL_REJECT_VALIDATION_COMPLETE";
export const UNINSTALL_STATUS_FETCHING_INIT = "UNINSTALL_STATUS_FETCHING_INIT";
export const UNINSTALL_STATUS_FETCHING_DISMISS = "UNINSTALL_STATUS_FETCHING_DISMISS";
export const VIEW_DETAILS_UNINSTALLATION_FETCHING_INIT = "VIEW_DETAILS_UNINSTALLATION_FETCHING_INIT";
export const UNINSTALLATION_VIEW_DETAILS_FETCHING_COMPLETE = "UNINSTALLATION_VIEW_DETAILS_FETCHING_COMPLETE";
export const VIEW_DETAILS_UN_FETCHING_DISMISS = "VIEW_DETAILS_UN_FETCHING_DISMISS";
export const NEXT_STEP_VIEW_COMPLETE = "NEXT_STEP_VIEW_COMPLETE";
export const VIEW_SUCESS_DETAILS_UNINSTALLATION_FETCHING_INIT = "VIEW_SUCESS_DETAILS_UNINSTALLATION_FETCHING_INIT";
export const VIEW_DETAILS_UNINSTALL_FETCHING_DISMISS = "VIEW_DETAILS_UNINSTALL_FETCHING_DISMISS";
export const EDIT_ERROR_RECORD_PREVIEW = "EDIT_ERROR_RECORD_PREVIEW"
export const EDIT_ERROR_RECORD_DISMISS = "EDIT_ERROR_RECORD_DISMISS"
export const EDIT_ERROR_RECORD = "EDIT_ERROR_RECORD"
export const EDIT_ERR_DETAILS_PREVW = "EDIT_ERR_DETAILS_PREVW"
export const VIEW_DETAILS_UN_FETCHING_INIT = "VIEW_DETAILS_UN_FETCHING_INIT";
export const DELETE_RECORDS_COMPLETE = "DELETE_RECORDS_COMPLETE";
export const ADD_HISTORY_ASSET_UNIN_INIT = "ADD_HISTORY_ASSET_UNIN_INIT";
export const ADD_HISTORY_UNIN_COMPLTED = "ADD_HISTORY_UNIN_COMPLTED";
export const ADD_HISTORY_ASSET_UNIN_DISSMISS = "ADD_HISTORY_ASSET_UNIN_DISSMISS";
export const UNIN_EVENT_TYPE_INIT = "UNIN_EVENT_TYPE_INIT";
export const UNIN_EVENT_TYPE_COMPLETE = "UNIN_EVENT_TYPE_COMPLETE";
export const UNIN_EVENT_TYPE_DISMISS = "UNIN_EVENT_TYPE_DISMISS";
export const DATA_FETCHING_HISTORY_INIT_COS = "DATA_FETCHING_HISTORY_INIT_COS";
export const DATA_HISTORY_FETCHING_DISMISS_COS = "DATA_HISTORY_FETCHING_DISMISS_COS";
export const ASSET_HISTORY_SEARCH_LIST_COMPLETE_COS = "ASSET_HISTORY_SEARCH_LIST_COMPLETE_COS";
export const FETCH_COS_FILE_LIST_COMPLETE = "FETCH_COS_FILE_LIST_COMPLETE";
export const DELETE_HISTORY_ASSET_UNIN_INIT = "DELETE_HISTORY_ASSET_UNIN_INIT";
export const DELETE_HISTORY_ASSET_UNIN_COMPLETE = "DELETE_HISTORY_ASSET_UNIN_COMPLETE";
export const DELETE_HISTORY_ASSET_UNIN_DISMISS = "DELETE_HISTORY_ASSET_UNIN_DISMISS";
export const DATA_REFETCH_SUBMIT_INIT = "DATA_REFETCH_SUBMIT_INIT"
export const DATA_REFETCH_COMPLETE = "DATA_REFETCH_COMPLETE"

const uninstallState = {
    uninstall_upload_popup: false,//to display file upload popup flag
    uninstallation_files: [],
    uninstallation_file_desc: "",
    uninstallationfilelist_refetch: false,
    uninstallation_file_title: "",
    search: "",//search keyword on installation file name listing
    search_view: "",
    page: 1,//page number on file list
    perpage: 10,//perpage limit on file list
    filter_status_uninstall: "",
    uninstallationfile_list: [], // all installation files 
    total_installationfiles: 0,//total count
    data_fetching: false,
    details_view: 0,
    uninstallationfile_details: {},
    uninstallationfileasset_list: [], // all installation files 
    uninstallationfileassetlist_refetch: false, //indicaction for fetch in progress
    download_popup: false,
    view_uninstall_approve_popup: false,
    uninstall_approve_btn_disable: false,
    view_uninstall_reject_popup: false,
    uninstall_confirm_btn_disable: false,
    uninstallation_download_data: [],
    add_btn_disable_unin: false,
    total_uninstallationfileasset: 0,
    viewpage: 1,
    viewperpage: 10,
    uninstal_status_fetch: false,
    status: [],
    uninstall_file_status_refetch: false,
    steps_fetching: false,
    status_retry: false,
    view_details_unin_data: [],
    view_details_fetching: false,
    uninstallation_file_id: null,
    uninstall_view_refetch: false,
    search_view_keyword: "",
    uninstall_view_page: 1,
    uninstall_view_perpage: 10,
    show_reject_data: false,
    e_invoice_no: "",
    e_mpan: "",
    e_mprn: "",
    e_supplier_code: "",
    e_uninst_date: "",
    e_serial_no: "",
    edit_error_btn: false,
    editErrorDetail: null,
    success_view_page: 1,
    success_view_perpage: 10,
    success_view_details_search: "",
    success_view_details_refetch: false,
    view_page: 1,
    view_perpage: 10,
    view_details_search: "",
    view_error_list_refetch: true,
    single_err_id: null,
    uninstall_remove_popup: false,
    remove_list_popup_count: 0,
    rem_btn_disable: false,
    unin_event_type: "",
    unin_his_event_type: "",
    unin_his_event_date: "",
    unin_his_supplier_code: "",
    unin_his_mpan: "",
    unin_his_mprn: "",
    unin_his_address: "",
    unin_his_postcode: "",
    unin_details_history: {},
    cnfm_delete_popup: false,
    dlt_btn_disble: false,
    serialNumber: "",
    assetHistoryData: [],
    count: 1000,
    export_View_details_support_flag_value: 1,
    download_view_detail_support_flag: false,
    module: 2,
    temp_id: null,
    uninstall_step: null,
    event_complete_flag: false,
    view_uninstall_details_refetch: true,
    current_uninstall_step: 0,
    isApiFetching: false,
    un_next_stp_enable:false
}

export default (state = uninstallState, action = {}) => {


    const actionObject = {
        SET_UNINSTALLATION_FILE_DATA: () => ({ ...state, ...action.payload }),
        OPEN_ERR_FILE_DOWNLOAD_POPUP: () => ({ ...state, ...action.payload }),
        CLOSE_ERR_FILE_DOWNLOAD_POPUP: () => ({ ...state, ...action.payload }),
        SET_FILTER_STATUS: () => ({ ...state, ...action.payload }),
        CLOSE_NEW_UPLOAD_POPUP: () => ({ ...state, ...action.payload }),
        OPEN_NEW_UPLOAD_POPUP: () => ({ ...state, ...action.payload }),
        FETCH_INSTALLATION_FILE_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
        ACCEPT_UNINSTALL_VALIDATION_SUCCESS: () => ({ ...state, ...action.payload }),
        UNINSTALLATION_ASSET_FETCHING_INIT: () => ({ ...state, ...action.payload, uninstallationfilelist_refetch: false, data_fetching: true }),
        UNINSTALLATION_ASSET_FETCHING_COMPLETE: () => ({ ...state, ...action.payload, data_fetching: false }),
        UNINSTALLATION_ASSET_FETCHING_DISMISS: () => ({ ...state, ...action.payload, data_fetching: false }),
        UPLOAD_INSTALLATION_FILE_COMPLETE: () => ({ ...state, ...action.payload }),
        DATA_FETCHING_INIT: () => ({ ...state, ...action.payload, data_fetching: true }),
        DATA_FETCHING_DISMISS: () => ({ ...state, ...action.payload, data_fetching: false }),
        DATA_SUBMIT_INIT: () => ({ ...state, ...action.payload, add_btn_disable_unin: true }),
        DATA_SUBMIT_DISMISS: () => ({ ...state, ...action.payload, add_btn_disable_unin: false }),
        HIDE_UNINSTALL_VALIDATION_FILE_REJECT: () => ({ ...state, view_uninstall_reject_popup: false, view_details: 1 }),
        OPEN_UNINSTALL_REJECT_VALIDATION_POPUP: () => ({ ...state, ...action.payload, view_uninstall_reject_popup: true }),
        UNINSTALL_REJECT_VALIDATION_COMPLETE: () => ({ ...state, ...action.payload, uninstall_confirm_btn_disable: false, view_details: 0 }),
        SET_REVIEW_UNINSTALLATION_FILE_DATA: () => ({ ...state, ...action.payload }),
        UNINSTALL_STATUS_FETCHING_INIT: () => ({ ...state, ...action.payload, isApiFetching: true }),
        UNINSTALL_STATUS_FETCHING_DISMISS: () => ({ ...state, ...action.payload, uninstall_file_status_refetch: false, uninstal_status_fetch: false, status_retry: false, isApiFetching: false }),
        VIEW_DETAILS_UNINSTALLATION_FETCHING_INIT: () => ({ ...state, ...action.payload, view_details_fetching: true }),
        UNINSTALLATION_VIEW_DETAILS_FETCHING_COMPLETE: () => ({ ...state, ...action.payload, view_error_list_refetch: false }),
        VIEW_DETAILS_UN_FETCHING_DISMISS: () => ({ ...state, ...action.payload, view_error_list_refetch: false, view_details_fetching: false }),
        NEXT_STEP_VIEW_COMPLETE: () => ({ ...state, ...action.payload, uninstall_file_status_refetch: true, steps_fetching: false}),
        VIEW_DETAILS_UN_FETCHING_INIT: () => ({ ...state, ...action.payload, success_view_details_refetch: true }),
        VIEW_DETAILS_UNINSTALL_FETCHING_DISMISS: () => ({ ...state, ...action.payload, success_view_details_refetch: false, view_details_fetching: false }),
        EDIT_ERROR_RECORD_PREVIEW: () => ({ ...state, ...action.payload, edit_error_btn: true }),
        EDIT_ERROR_RECORD: () => ({ ...state, ...action.payload, edit_error_btn: false, view_error_list_refetch: true }),
        EDIT_ERROR_RECORD_DISMISS: () => ({ ...state, ...action.payload, edit_error_btn: false }),
        EDIT_ERR_DETAILS_PREVW: () => ({ ...state, ...action.payload }),
        DELETE_RECORDS_COMPLETE: () => ({ ...state, ...action.payload, view_details_fetching: false, view_error_list_refetch: true, rem_btn_disable: false, remove_popup_count: 0 }),
        ADD_HISTORY_ASSET_UNIN_INIT: () => ({ ...state, ...action.payload, unin_his_add_btn_dsbl: true, status_complete: false, event_complete_flag: false }),
        ADD_HISTORY_UNIN_COMPLTED: () => ({ ...state, ...action.payload }),
        ADD_HISTORY_ASSET_UNIN_DISSMISS: () => ({ ...state, ...action.payload, unin_his_add_btn_dsbl: false, status_complete: false, event_complete_flag: false }),
        UNIN_EVENT_TYPE_INIT: () => ({ ...state, ...action.payload }),
        UNIN_EVENT_TYPE_COMPLETE: () => ({ ...state, ...action.payload }),
        UNIN_EVENT_TYPE_DISMISS: () => ({ ...state, ...action.payload }),
        DATA_FETCHING_HISTORY_INIT_COS: () => ({ ...state, ...action.payload, asset_history_fetching: true }),
        DATA_HISTORY_FETCHING_DISMISS_COS: () => ({ ...state, ...action.payload, asset_history_fetching: false, view_uninstall_details_refetch: false }),
        ASSET_HISTORY_SEARCH_LIST_COMPLETE_COS: () => ({ ...state, ...action.payload }),
        FETCH_COS_FILE_LIST_COMPLETE: () => ({ ...state, ...action.payload, view_uninstall_details_refetch: false }),
        VIEW_SUCESS_DETAILS_UNINSTALLATION_FETCHING_INIT: () => ({ ...state, ...action.payload, view_details_fetching: true }),
        DELETE_HISTORY_ASSET_UNIN_INIT: () => ({ ...state, ...action.payload, dlt_btn_disble: true, status_complete: false, event_complete_flag: false }),
        DELETE_HISTORY_ASSET_UNIN_COMPLETE: () => ({ ...state, ...action.payload, dlt_btn_disble: false, direct_to_steps: true, view_uninstall_details_refetch: true, status_complete: true, event_complete_flag: true }),
        DELETE_HISTORY_ASSET_UNIN_DISMISS: () => ({ ...state, ...action.payload, dlt_btn_disble: false, status_complete: false, event_complete_flag: false }),
        DATA_REFETCH_SUBMIT_INIT: () => ({ ...state, ...action.payload }),
        DATA_REFETCH_COMPLETE: () => ({ ...state, ...action.payload }),
    };

    if (actionObject[action.type] === undefined) {
        return state; // Return unchanged state
    } else {
        // Call the appropriate action handler and return the updated state
        return actionObject[action.type]();
    }
};
